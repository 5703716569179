import React from 'react'

interface INotFoundImage {
  width?: string
  height?: string
}

const NotFoundImage = ({ width = '270', height = '270' }: INotFoundImage) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 271 271"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21179_9055)">
        <path
          d="M255.364 79.3114C251.795 28.5837 182.145 -16.9329 139.089 7.33362C109.626 23.939 89.4868 26.4729 56.559 34.7284C6.912 47.1749 -0.631158 109.135 47.1575 126.066C74.5265 135.763 124.25 123.663 135.216 153.821C144.267 178.71 121.34 203.276 141.525 236.583C167.506 279.45 211.544 275.918 225.2 256.928C237.01 240.507 220.97 212.043 217.798 194.836C210.039 152.74 256.602 120.616 255.364 79.3114Z"
          fill="url(#paint0_linear_21179_9055)"
        />
        <path
          opacity="0.1"
          d="M244.056 59.7056H48.0943C45.1842 59.7056 42.8253 62.0645 42.8253 64.9746V184.091C30.335 187.314 21.1073 198.656 21.1073 212.153C21.1073 228.158 34.0818 241.133 50.087 241.133C62.1927 241.133 72.56 233.707 76.8954 223.165H244.056C246.966 223.165 249.325 220.806 249.325 217.896V64.9746C249.325 62.0645 246.966 59.7056 244.056 59.7056Z"
          fill="#565656"
        />
        <path
          d="M244.056 210.519H220.378L147.131 207.885L71.7718 210.519H48.0943C45.1842 210.519 42.8253 208.16 42.8253 205.25V91.5305L147.393 88.896L249.325 91.5305V205.25C249.325 208.16 246.966 210.519 244.056 210.519Z"
          fill="#FFE7DB"
        />
        <path
          d="M42.8253 77.7817H84.1251V91.5308H42.8253V77.7817Z"
          fill="#959595"
        />
        <path
          d="M84.1251 77.7817H125.425V91.5308H84.1251V77.7817Z"
          fill="#C4C4C4"
        />
        <path
          d="M125.425 77.7817H166.725V91.5308H125.425V77.7817Z"
          fill="#737373"
        />
        <path
          d="M166.725 77.7817H208.025V91.5308H166.725V77.7817Z"
          fill="#C1C1C1"
        />
        <path
          d="M208.025 77.7817H249.325V91.5308H208.025V77.7817Z"
          fill="#FFF5EF"
        />
        <path
          d="M220.378 210.519H71.7723V165.419C71.7723 163.964 72.952 162.785 74.4068 162.785H217.744C219.199 162.785 220.378 163.964 220.378 165.419V210.519Z"
          fill="white"
        />
        <path
          d="M249.325 77.7818V52.3286C249.325 49.4185 246.966 47.0596 244.056 47.0596H48.0943C45.1842 47.0596 42.8253 49.4185 42.8253 52.3286V77.7812H249.325V77.7818Z"
          fill="#FFAA66"
        />
        <path
          d="M221.921 70.4943H134.775C131.19 70.4943 128.283 67.5874 128.283 64.0018C128.283 60.4162 131.19 57.5093 134.775 57.5093H221.921C225.507 57.5093 228.414 60.4162 228.414 64.0018C228.414 67.5874 225.507 70.4943 221.921 70.4943Z"
          fill="#FFE7DB"
        />
        <path
          d="M59.83 66.6968C58.3752 66.6968 57.1955 65.5171 57.1955 64.0623V64.0017C57.1955 62.5469 58.3752 61.3672 59.83 61.3672C61.2848 61.3672 62.4646 62.5469 62.4646 64.0017V64.0623C62.4646 65.5171 61.2848 66.6968 59.83 66.6968Z"
          fill="#F48869"
        />
        <path
          d="M68.0898 66.6968C66.635 66.6968 65.4553 65.5171 65.4553 64.0623V64.0017C65.4553 62.5469 66.635 61.3672 68.0898 61.3672C69.5446 61.3672 70.7243 62.5469 70.7243 64.0017V64.0623C70.7243 65.5171 69.5446 66.6968 68.0898 66.6968Z"
          fill="#FFD5BA"
        />
        <path
          d="M76.349 66.6968C74.8942 66.6968 73.7145 65.5171 73.7145 64.0623V64.0017C73.7145 62.5469 74.8942 61.3672 76.349 61.3672C77.8038 61.3672 78.9835 62.5469 78.9835 64.0017V64.0623C78.9835 65.5171 77.8043 66.6968 76.349 66.6968Z"
          fill="#737373"
        />
        <path
          d="M97.1317 70.2456C96.4741 70.2456 95.816 70.0011 95.3049 69.509L91.0069 65.3717C90.4911 64.8754 90.1992 64.1899 90.1992 63.4738C90.1992 62.7577 90.4906 62.0722 91.0069 61.5759L95.3049 57.4386C96.3529 56.4296 98.0211 56.4612 99.0296 57.5098C100.039 58.5578 100.007 60.2254 98.9585 61.2345L96.6327 63.4738L98.9585 65.7131C100.007 66.7222 100.039 68.3898 99.0296 69.4378C98.5127 69.9753 97.8225 70.2456 97.1317 70.2456Z"
          fill="#F48869"
        />
        <path
          d="M110.044 70.2458C109.353 70.2458 108.663 69.9755 108.146 69.4386C107.137 68.3901 107.168 66.7224 108.216 65.7134L110.542 63.4741L108.216 61.2347C107.168 60.2257 107.137 58.558 108.146 57.5095C109.155 56.461 110.823 56.4299 111.871 57.4389L116.168 61.5762C116.684 62.0725 116.975 62.758 116.975 63.4741C116.975 64.1901 116.684 64.8756 116.168 65.372L111.871 69.5092C111.36 70.0013 110.702 70.2458 110.044 70.2458Z"
          fill="#F48869"
        />
        <path
          d="M165.696 136.789H160.542C155.463 136.789 151.332 132.657 151.332 127.579V111.671C151.332 106.592 155.464 102.46 160.542 102.46H165.696C170.774 102.46 174.906 106.592 174.906 111.671V127.579C174.906 132.657 170.774 136.789 165.696 136.789ZM160.541 107.729C158.368 107.729 156.6 109.497 156.6 111.671V127.579C156.6 129.753 158.368 131.52 160.541 131.52H165.695C167.869 131.52 169.636 129.752 169.636 127.579V111.671C169.636 109.497 167.869 107.729 165.695 107.729H160.541Z"
          fill="#F48869"
        />
        <path
          d="M196.29 126.034C195.944 126.034 195.593 125.966 195.254 125.821L181.697 120.021C180.728 119.606 180.099 118.653 180.099 117.599V105.095C180.099 103.64 181.278 102.46 182.734 102.46C184.189 102.46 185.368 103.64 185.368 105.095V115.861L197.327 120.977C198.665 121.549 199.286 123.098 198.713 124.436C198.285 125.435 197.312 126.034 196.29 126.034Z"
          fill="#F48869"
        />
        <path
          d="M197.836 136.193C196.38 136.193 195.201 135.014 195.201 133.559V105.095C195.201 103.64 196.38 102.46 197.836 102.46C199.291 102.46 200.47 103.64 200.47 105.095V133.559C200.47 135.014 199.29 136.193 197.836 136.193Z"
          fill="#F48869"
        />
        <path
          d="M141.958 126.034C141.612 126.034 141.261 125.966 140.923 125.821L127.365 120.021C126.396 119.606 125.767 118.653 125.767 117.599V105.095C125.767 103.64 126.947 102.46 128.402 102.46C129.857 102.46 131.036 103.64 131.036 105.095V115.861L142.996 120.977C144.333 121.549 144.954 123.098 144.381 124.436C143.953 125.435 142.98 126.034 141.958 126.034Z"
          fill="#F48869"
        />
        <path
          d="M143.504 136.193C142.049 136.193 140.869 135.014 140.869 133.559V105.095C140.869 103.64 142.049 102.46 143.504 102.46C144.959 102.46 146.139 103.64 146.139 105.095V133.559C146.139 135.014 144.959 136.193 143.504 136.193Z"
          fill="#F48869"
        />
        <path
          d="M94.3148 117.237C92.8601 117.237 91.6803 116.057 91.6803 114.602V114.542C91.6803 113.087 92.8601 111.907 94.3148 111.907C95.7696 111.907 96.9494 113.087 96.9494 114.542V114.602C96.9494 116.057 95.7696 117.237 94.3148 117.237Z"
          fill="#F48869"
        />
        <path
          d="M110.834 117.237C109.379 117.237 108.199 116.057 108.199 114.602V114.542C108.199 113.087 109.379 111.907 110.834 111.907C112.289 111.907 113.468 113.087 113.468 114.542V114.602C113.468 116.057 112.289 117.237 110.834 117.237Z"
          fill="#F48869"
        />
        <path
          d="M96.4973 127.342C95.8228 127.342 95.1489 127.085 94.6341 126.571C93.6051 125.542 93.6051 123.874 94.6341 122.845C96.7549 120.724 99.5749 119.556 102.574 119.556C105.574 119.556 108.393 120.724 110.514 122.845C111.543 123.874 111.543 125.542 110.514 126.571C109.485 127.6 107.817 127.599 106.788 126.571C105.663 125.445 104.166 124.825 102.574 124.825C100.982 124.825 99.4854 125.445 98.3599 126.571C97.8456 127.085 97.1712 127.342 96.4973 127.342Z"
          fill="#F48869"
        />
        <path
          d="M203.543 154.667H196.988L191.821 152.033L185.975 154.667H173.169L168.335 152.033L162.155 154.667H149.349L144.595 152.033L138.336 154.667H125.53L120.237 152.033L114.517 154.667H101.711L95.9108 152.033L90.6971 154.667H88.6074C85.5688 154.667 83.105 152.204 83.105 149.165C83.105 146.126 85.5682 143.662 88.6074 143.662H95.1621L101 146.297L106.175 143.662H118.981L124.301 146.297L129.995 143.662H142.801L148.464 146.297L153.815 143.662H166.621L171.724 146.297L177.635 143.662H190.441L196.564 146.297L201.454 143.662H203.544C206.583 143.662 209.047 146.125 209.047 149.165C209.045 152.204 206.581 154.667 203.543 154.667Z"
          fill="#F08769"
        />
        <path
          d="M101.711 154.667H90.6971L95.1626 143.663H106.176L101.711 154.667Z"
          fill="#FF4D4D"
        />
        <path
          d="M125.53 154.667H114.516L118.982 143.663H129.995L125.53 154.667Z"
          fill="#FF4D4D"
        />
        <path
          d="M149.349 154.667H138.336L142.801 143.663H153.814L149.349 154.667Z"
          fill="#FF4D4D"
        />
        <path
          d="M173.169 154.667H162.155L166.62 143.663H177.634L173.169 154.667Z"
          fill="#FF4D4D"
        />
        <path
          d="M196.988 154.667H185.974L190.44 143.663H201.453L196.988 154.667Z"
          fill="#FF4D4D"
        />
        <path
          d="M165.7 187.083H149.766C148.31 187.083 147.131 185.903 147.131 184.448C147.131 182.994 148.31 181.814 149.766 181.814H165.7C167.155 181.814 168.334 182.994 168.334 184.448C168.334 185.903 167.155 187.083 165.7 187.083Z"
          fill="#C1C1C1"
        />
        <path
          d="M138.531 187.083H105.913C104.458 187.083 103.279 185.903 103.279 184.448C103.279 182.994 104.458 181.814 105.913 181.814H138.531C139.986 181.814 141.166 182.994 141.166 184.448C141.166 185.903 139.986 187.083 138.531 187.083Z"
          fill="#C1C1C1"
        />
        <path
          d="M165.7 196.468H105.913C104.458 196.468 103.279 195.289 103.279 193.834C103.279 192.379 104.458 191.199 105.913 191.199H165.7C167.155 191.199 168.334 192.379 168.334 193.834C168.334 195.289 167.155 196.468 165.7 196.468Z"
          fill="#C1C1C1"
        />
        <path
          d="M138.531 177.698H105.913C104.458 177.698 103.279 176.518 103.279 175.063C103.279 173.608 104.458 172.429 105.913 172.429H138.531C139.986 172.429 141.166 173.608 141.166 175.063C141.166 176.518 139.986 177.698 138.531 177.698Z"
          fill="#C1C1C1"
        />
        <path
          d="M187.671 187.083H179.779C178.323 187.083 177.144 185.903 177.144 184.448C177.144 182.994 178.323 181.814 179.779 181.814H187.671C189.126 181.814 190.306 182.994 190.306 184.448C190.306 185.903 189.126 187.083 187.671 187.083Z"
          fill="#FFAA66"
        />
        <path
          d="M198.874 196.468H179.778C178.323 196.468 177.144 195.289 177.144 193.834C177.144 192.379 178.323 191.199 179.778 191.199H198.874C200.329 191.199 201.508 192.379 201.508 193.834C201.508 195.289 200.329 196.468 198.874 196.468Z"
          fill="#FFAA66"
        />
        <path
          d="M93.2763 187.636C91.8215 187.636 90.6418 186.456 90.6418 185.001V175.063C90.6418 173.608 91.8215 172.429 93.2763 172.429C94.7311 172.429 95.9108 173.608 95.9108 175.063V185.001C95.9108 186.456 94.7311 187.636 93.2763 187.636Z"
          fill="#F48869"
        />
        <path
          d="M93.2763 196.498C91.8215 196.498 90.6418 195.318 90.6418 193.864V193.803C90.6418 192.348 91.8215 191.168 93.2763 191.168C94.7311 191.168 95.9108 192.348 95.9108 193.803V193.864C95.9108 195.318 94.7311 196.498 93.2763 196.498Z"
          fill="#F48869"
        />
        <path
          d="M50.087 228.487C66.0921 228.487 79.0668 215.512 79.0668 199.507C79.0668 183.502 66.0921 170.527 50.087 170.527C34.082 170.527 21.1073 183.502 21.1073 199.507C21.1073 215.512 34.082 228.487 50.087 228.487Z"
          fill="#737373"
        />
        <path
          d="M50.087 228.487C56.4515 228.487 61.611 215.512 61.611 199.507C61.611 183.502 56.4515 170.527 50.087 170.527C43.7226 170.527 38.5631 183.502 38.5631 199.507C38.5631 215.512 43.7226 228.487 50.087 228.487Z"
          fill="#C4C4C4"
        />
        <path
          d="M38.5631 199.507H61.611H79.0668C79.0668 183.502 66.0923 170.527 50.087 170.527C34.0818 170.527 21.1073 183.502 21.1073 199.507H38.5631Z"
          fill="#C1C1C1"
        />
        <path
          d="M61.611 199.507C61.611 183.502 56.4515 170.527 50.087 170.527C43.7226 170.527 38.5631 183.502 38.5631 199.507H61.611Z"
          fill="#737373"
        />
        <path
          d="M27.7516 217.972C33.068 212.946 41.0981 209.742 50.087 209.742C59.076 209.742 67.1113 212.946 72.4225 217.972C67.106 224.395 59.076 228.484 50.087 228.484C41.1033 228.484 33.068 224.395 27.7516 217.972Z"
          fill="#C4C4C4"
        />
        <path
          d="M27.7516 181.042C33.068 174.619 41.0981 170.525 50.087 170.525C59.076 170.525 67.1113 174.619 72.4225 181.042C67.1113 186.069 59.076 189.273 50.087 189.273C41.0981 189.273 33.068 186.069 27.7516 181.042Z"
          fill="#737373"
        />
        <path
          d="M39.5542 211.286C42.821 210.29 46.3724 209.742 50.087 209.742C53.8017 209.742 57.3583 210.29 60.6199 211.286C58.8231 221.418 54.7818 228.484 50.087 228.484C45.3923 228.484 41.351 221.418 39.5542 211.286Z"
          fill="#959595"
        />
        <path
          d="M39.5542 187.728C41.351 177.591 45.3923 170.525 50.087 170.525C54.7818 170.525 58.8231 177.591 60.6199 187.728C57.3583 188.724 53.8017 189.272 50.087 189.272C46.3724 189.272 42.821 188.724 39.5542 187.728Z"
          fill="#C4C4C4"
        />
        <path
          d="M244.056 44.4258H48.0943C43.7363 44.4258 40.1907 47.9713 40.1907 52.3293V155.601C40.1907 157.056 41.3705 158.235 42.8253 158.235C44.28 158.235 45.4598 157.056 45.4598 155.601V52.3293C45.4598 50.8767 46.6416 49.6948 48.0943 49.6948H244.056C245.509 49.6948 246.691 50.8767 246.691 52.3293V205.251C246.691 206.703 245.509 207.885 244.056 207.885H94.1678C92.7131 207.885 91.5333 209.065 91.5333 210.52C91.5333 211.974 92.7131 213.154 94.1678 213.154H244.056C248.414 213.154 251.96 209.609 251.96 205.251V52.3293C251.959 47.9713 248.414 44.4258 244.056 44.4258Z"
          fill="#565656"
        />
        <path
          d="M50.0876 167.893C32.6555 167.893 18.4733 182.075 18.4733 199.507C18.4733 216.939 32.6555 231.122 50.0876 231.122C67.5197 231.122 81.7018 216.939 81.7018 199.507C81.7018 182.075 67.5197 167.893 50.0876 167.893ZM50.0876 225.853C35.5608 225.853 23.7424 214.034 23.7424 199.507C23.7424 184.981 35.5608 173.162 50.0876 173.162C64.6143 173.162 76.4328 184.981 76.4328 199.507C76.4328 214.034 64.6143 225.853 50.0876 225.853Z"
          fill="#565656"
        />
        <path
          d="M92.8337 249.124C91.3789 249.124 90.1992 247.944 90.1992 246.49C90.1992 244.891 88.8993 243.592 87.3012 243.592C85.8465 243.592 84.6667 242.412 84.6667 240.957C84.6667 239.502 85.8465 238.323 87.3012 238.323C88.8993 238.323 90.1992 237.023 90.1992 235.425C90.1992 233.97 91.3789 232.79 92.8337 232.79C94.2885 232.79 95.4683 233.97 95.4683 235.425C95.4683 237.023 96.7681 238.323 98.3662 238.323C99.821 238.323 101.001 239.502 101.001 240.957C101.001 242.412 99.821 243.592 98.3662 243.592C96.7681 243.592 95.4683 244.891 95.4683 246.49C95.4683 247.944 94.289 249.124 92.8337 249.124Z"
          fill="#FFA67D"
        />
        <path
          d="M201.453 36C199.998 36 198.818 34.8203 198.818 33.3655C198.818 31.7674 197.518 30.4675 195.92 30.4675C194.465 30.4675 193.286 29.2878 193.286 27.833C193.286 26.3782 194.465 25.1985 195.92 25.1985C197.518 25.1985 198.818 23.8986 198.818 22.3005C198.818 20.8458 199.998 19.666 201.453 19.666C202.908 19.666 204.087 20.8458 204.087 22.3005C204.087 23.8986 205.388 25.1985 206.985 25.1985C208.441 25.1985 209.62 26.3782 209.62 27.833C209.62 29.2878 208.441 30.4675 206.985 30.4675C205.388 30.4675 204.087 31.7674 204.087 33.3655C204.087 34.8208 202.908 36 201.453 36Z"
          fill="#F48869"
        />
        <path
          d="M122.256 238.292C120.801 238.292 119.622 237.112 119.622 235.658C119.622 234.059 118.322 232.76 116.724 232.76C115.269 232.76 114.089 231.58 114.089 230.125C114.089 228.67 115.269 227.491 116.724 227.491C118.322 227.491 119.622 226.191 119.622 224.593C119.622 223.138 120.801 221.958 122.256 221.958C123.711 221.958 124.891 223.138 124.891 224.593C124.891 226.191 126.19 227.491 127.789 227.491C129.243 227.491 130.423 228.67 130.423 230.125C130.423 231.58 129.243 232.76 127.789 232.76C126.19 232.76 124.891 234.059 124.891 235.658C124.891 237.112 123.711 238.292 122.256 238.292Z"
          fill="#C4C4C4"
        />
        <path
          d="M242.767 28.4692C242.094 28.4692 241.419 28.2121 240.905 27.6978C239.876 26.6688 239.876 25.0006 240.905 23.9721L246.174 18.703C247.202 17.6745 248.871 17.6745 249.899 18.703C250.928 19.7321 250.928 21.4002 249.899 22.4288L244.63 27.6978C244.116 28.2121 243.441 28.4692 242.767 28.4692Z"
          fill="#C4C4C4"
        />
        <path
          d="M235.108 246.226C234.434 246.226 233.759 245.969 233.246 245.455C232.217 244.426 232.217 242.757 233.246 241.729L238.515 236.46C239.543 235.431 241.212 235.431 242.24 236.46C243.269 237.489 243.269 239.157 242.24 240.186L236.971 245.455C236.457 245.969 235.782 246.226 235.108 246.226Z"
          fill="#F48869"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_21179_9055"
          x1="91.1508"
          y1="189.731"
          x2="240.731"
          y2="40.1508"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.2617" stopColor="#FCE8DD" stopOpacity="0.24" />
          <stop offset="0.629" stopColor="#FCE8DD" />
        </linearGradient>
        <clipPath id="clip0_21179_9055">
          <rect
            width="269.775"
            height="269.775"
            fill="white"
            transform="translate(0.328857 0.616211)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NotFoundImage
