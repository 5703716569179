import './button-redirect.scss'

import type { ReactNode } from 'react'
import React from 'react'

interface IButtonRedirect {
  link: string
  children: ReactNode
  target?: string
}

const baseClass = 'container-buttonRedirect'

function ButtonRedirect({
  link,
  children,
  target = '_blank',
}: IButtonRedirect) {
  return (
    <div className={baseClass}>
      <a href={link} target={target} rel="noreferrer noopener">
        <button>{children}</button>
      </a>
    </div>
  )
}

export default ButtonRedirect
