import './404.scss'

import React, { useEffect, useRef, useState } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import { useSession } from 'src/sdk/session'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import { useCampaignContext } from 'src/contexts/campaign-context'
import ButtonRedirect from 'src/components/ButtonRedirect'
import notFoundData from 'src/mocks/listPages/404.json'
import NotFoundImage from 'src/images/svg/not-found-image'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

interface ICardNotFound {
  image: {
    src: string
    alt: string
  }
  title: string
  description: string
  button: {
    label: string
    link: string
  }
}

const baseClass = 'not-found'

function CardNotFound({ image, title, description, button }: ICardNotFound) {
  const link = `${window?.location?.origin}${button?.link}`

  return (
    <div className={`${baseClass}-listCards-cardItem`}>
      <div className={`${baseClass}-listCards-cardItem__image`}>
        <img src={image?.src} alt={image?.alt} loading="eager" />
      </div>
      <div className={`${baseClass}-listCards-cardItem__title`}>
        <h3 className="text">{title}</h3>
      </div>
      <div className={`${baseClass}-listCards-cardItem__description`}>
        <p className="text">{description}</p>
      </div>
      <ButtonRedirect link={link} target="_self">
        {button?.label}
      </ButtonRedirect>
    </div>
  )
}

function Page() {
  const { setCampaignPage } = useCampaignContext()
  const { person } = useSession()
  const [didMount, setDidMount] = useState(false)
  const { sendPageViewEvent } = usePageViewEvent('404', person)
  const { isMobile } = useWindowDimensions()
  const sizeNotFoundImage = isMobile ? '120' : '270'

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  setCampaignPage(false)

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (!didMount) {
        sendPageViewEvent()
        setDidMount(true)
      }
    }, 500)
  }, [person])

  return (
    <>
      <GatsbySeo
        title="Vivara, Erro 404!"
        description="Error 400 page"
        language="pt-BR"
        noindex
        nofollow
      />
      <div className={`a-${baseClass}`}>
        <div className={baseClass}>
          <div className={`${baseClass}-topBar`}>
            <div className={`${baseClass}-imageMain`}>
              <NotFoundImage
                width={sizeNotFoundImage}
                height={sizeNotFoundImage}
              />
            </div>
            <div className={`${baseClass}-information`}>
              <div className={`${baseClass}-information__title`}>
                <h2 className="text">ALGO INESPERADO OCORREU...</h2>
              </div>
              <span className={`${baseClass}-information__description`}>
                Mas não desista! Temos um catálogo incrível de itens que podem
                te agradar. <span>Que tal dar uma olhada?</span>
              </span>
              <button
                className={`${baseClass}-information__btn-home`}
                onClick={() => (window.location.href = '/')}
              >
                ir para home
              </button>
            </div>
          </div>
          <div className={`${baseClass}-listCards`}>
            {notFoundData?.map((itemCard, index) => (
              <CardNotFound key={index} {...itemCard} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query NotFoundPageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
